<template>
  <v-app>
    <EnvMark />
    <v-main>
      <div v-if="CONST.IS_LITE">
        <LiteHeader :authState="authState" />
        <router-view name="lite" :authState="authState" />
        <LiteFooter />
      </div>
      <div v-else>
        <Header :authState="authState" />
        <amplify-authenticator>
          <div v-if="authState === 'signedin'">
            <router-view />
          </div>
        </amplify-authenticator>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import EnvMark from "./components/EnvMark";
import Header from "./components/Header";
import LiteHeader from "./components/lite/LiteHeader";
import LiteFooter from "./components/lite/LiteFooter";
import { CONST } from "./const";
import { Auth } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  components: { EnvMark, Header, LiteHeader, LiteFooter },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if (authState === "signedin" && authData) {
        //  console.log("signed in.");
        //  console.log(authData);
        this.$router.push({ name: "Home" });
      }
    });
    if (this.authState === undefined) {
      Auth.currentAuthenticatedUser().then((authData) => {
        this.authState = AuthState.SignedIn;
        this.user = authData;
      });
    }
  },
  data() {
    return {
      CONST,
      user: undefined,
      authState: undefined,
    };
  },

  // beforeDestroy() {
  //   return onAuthUIStateChange;
  // },
};
</script>
