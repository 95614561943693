<template>
  <v-container fluid fill-height class="home-hero__content">
    <v-row>
      <v-col class="home-hero__content-text">
        <a href="#analysis" class="title-text">
          {{ $t("title") }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LiteHome",
};
</script>

<style lang="scss" scoped>
.home-hero__content {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100vh;

  &-text {
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    z-index: 1;
  }
}

.home-hero__content::before {
  background: url("../../assets/meeting.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
  z-index: 0;
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.title-text {
  text-decoration: none;
  color: white;
}
</style>
