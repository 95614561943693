/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "batch",
            "endpoint": "https://i55pcvys28.execute-api.ap-northeast-1.amazonaws.com/devliteb",
            "region": "ap-northeast-1"
        },
        {
            "name": "lite1",
            "endpoint": "https://h16nw5h4t0.execute-api.ap-northeast-1.amazonaws.com/devliteb",
            "region": "ap-northeast-1"
        },
        {
            "name": "step1",
            "endpoint": "https://qeclxzwhkl.execute-api.ap-northeast-1.amazonaws.com/devliteb",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:f96d6313-3661-4f3e-8b5d-bc375596a302",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_4oyMEC1l2",
    "aws_user_pools_web_client_id": "51fcshog10848k45142quk0g96",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "teamvisresults234824-devliteb",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
