import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import panZoom from "vue-panzoom";
import VueScrollTo from "vue-scrollto";

import awsconfig from "./aws-exports";
import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";
import VTooltip from "v-tooltip";
import VueI18n from "vue-i18n";

Amplify.configure(awsconfig);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(panZoom);
Vue.use(VueScrollTo);
Vue.use(VTooltip);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja", // set locale
  messages: {
    en: require("./lang/en.json"),
    ja: require("./lang/ja.json"),
  },
});

new Vue({
  vuetify,
  router,
  panZoom,
  VueScrollTo,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
