<template>
  <div v-if="CONST.IS_DEV" class="envmark">
    {{ CONST.ENV }}
  </div>
</template>

<style lang="scss">
.envmark {
  position: absolute;
  left: -50px;
  top: 35px;
  z-index: 100;
  background: rgba(255, 0, 0, 0.75);
  color: rgba(0, 0, 0, 0.75);
  transform: rotate(-45deg);
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  user-select: none;
  &:hover {
    background: rgba(255, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>

<script>
import { CONST } from "../const";
export default {
  data: () => ({
    CONST: CONST,
  }),
};
</script>
