<template>
  <v-container>
    <div class="upload ma-4">
      <v-row>
        <h1 class="text-h2 ma-4 mb-8">
          meetings
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab dark v-bind="attrs" v-on="on" color="indigo">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">New Meeting</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="workshop"
                        v-model="workshop"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="team"
                        v-model="team"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="numer of members"
                        v-model="members"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4" sm="2">
                      <v-select
                        :items="
                          Array.from(
                            Array(24),
                            (v, k) => ('0' + k).slice(-2) + ':'
                          )
                        "
                        label="hour"
                        v-model="hour"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="2">
                      <v-select
                        :items="
                          Array.from(
                            Array(60),
                            (v, k) => ('0' + k).slice(-2) + ':'
                          )
                        "
                        label="minute"
                        v-model="minute"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="2">
                      <v-select
                        :items="
                          Array.from(Array(60), (v, k) => ('0' + k).slice(-2))
                        "
                        label="sec"
                        v-model="second"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="onCreateMtg">
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </h1>
      </v-row>
      <v-data-table
        :headers="header"
        :items="mtgs"
        :options.sync="table_options"
        @click:row="onClickMtg"
      ></v-data-table>
      <amplify-authenticator />
    </div>
  </v-container>
</template>

<script>
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Upload",
  data() {
    return {
      header: [
        {
          text: "workshop",
          value: "workshop",
        },
        {
          text: "team",
          value: "team",
        },
        {
          text: "start at",
          value: "start",
        },
      ],
      mtgs: [],
      table_options: {
        sortBy: ["start"],
        descending: [false],
      },
      dialog: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      workshop: "",
      team: "",
      members: 0,
      hour: "00:",
      minute: "00:",
      second: "00",
    };
  },
  methods: {
    loadMtgName: function(id, key) {
      const dataExpireSeconds = 60;
      Storage.get(key, {
        level: "private",
        expires: dataExpireSeconds,
      })
        .then((url) => {
          this.axios.get(url).then((result) => {
            let ind = this.mtgs.findIndex((mtg) => mtg.id == id);
            console.log(result);
            this.mtgs.splice(
              ind,
              1,
              Object.assign(
                {
                  workshop: result.data.workshop,
                  team: result.data.team,
                  start: result.data.start,
                },
                this.mtgs[ind]
              )
            );
            console.log(this.mtgs);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickMtg: function(data) {
      console.log(data);
      this.$router.push(data.url);
    },
    onCreateMtg: function() {
      console.log(this.date);
      console.log(this);
      const start = this.date + " " + this.hour + this.minute + this.second;
      const end = start;
      const mtg_info = {
        workshop: this.workshop,
        team: this.team,
        members: Number(this.members),
        stage: 0,
        start: start,
        end: end,
      };
      const id = uuidv4();
      const content = JSON.stringify(mtg_info);
      const name = "mtgs/" + id + "/mtg_info.json";
      Storage.put(name, content, {
        level: "private",
        contentType: "text/json",
      })
        .then((response) => {
          // response 処理
          console.log(response);
          let url = "/mtg/" + id;
          this.mtgs.push(Object.assign({ id: id, url: url }, mtg_info));
        })
        .catch((error) => {
          // error 処理
          console.log(error);
        });
      this.dialog = false;
    },
  },
  mounted: function() {
    Storage.list("mtgs/", {
      level: "private",
      // identityId: "xxxxxxx" // the identityId of that user
    })
      .then((result) => {
        console.log(result);
        result.forEach((res) => {
          if (res.size) {
            let d = res.key.split("/");
            if (d.length == 3 && d[2] == "mtg_info.json") {
              let url = "/mtg/" + d[1];
              this.mtgs.push({
                id: d[1],
                url: url,
              });
              this.loadMtgName(d[1], res.key);
            }
          }
        });
      })
      .catch((err) => console.log(err));
  },
};
</script>
