<template>
  <v-row class="text-center">
    <v-col cols="12">
      <div class="my-10" />
      <h2 id="analysis" class="mx-5 mb-2">
        {{ $t("copy_title") }}
      </h2>
      <div class="mx-5 my-0" />
      <p class="main-text">
        {{ $t("copy_text") }}
      </p>
      <div class="my-5" v-if="authState === 'signedin'"></div>
      <div class="mb-6" v-else>
        <p class="mx-5 text-notice">
          {{ $t("copy_not_login_text") }}
          <a href="/signin" class="link_simple">[{{ $t("login") }}]</a>
        </p>
        <p class="mx-5 text-notice">
          {{ $t("copy_form_1") }}
          <a
            href="https://forms.gle/wbSFSiBfwt4ppEyw7"
            class="link_simple"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t("copy_form_2") }}
          </a>
          {{ $t("copy_form_3") }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LiteCopy",
  props: ["authState"],
};
</script>

<style lang="scss" scoped>
.main-text {
  justify-content: center;
  align-items: center;
  max-width: 520px;
  margin: auto;
  padding: 5px 20px 10px;
}
.text-notice {
  color: grey;
  margin-bottom: 0px;
}
.link_simple {
  text-decoration: none;
}

h2 {
  color: #5e5e5e;
}
h3 {
  color: #5e5e5e;
}
</style>
