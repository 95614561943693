<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <!--
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        transition="scale-transition"
        width="40"
      />
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
        width="100"
      />
      -->
      <v-btn text href="/">
        teamvis
      </v-btn>
    </div>

    <v-spacer></v-spacer>

    <amplify-sign-out v-if="authState === 'signedin'"></amplify-sign-out>
  </v-app-bar>
</template>

<script>
export default {
  props: ["authState"],
};
</script>
