<template>
  <section class="home-hero">
    <LiteHome />
    <LiteCopy :authState="authState" />
    <LiteAnalyzer :authState="authState" />
    <LiteDescription />
  </section>
</template>

<script>
import LiteHome from "../components/lite/LiteHome";
import LiteCopy from "../components/lite/LiteCopy";
import LiteAnalyzer from "../components/lite/LiteAnalyzer";
import LiteDescription from "../components/lite/LiteDescription";

export default {
  name: "Lite",
  props: ["authState"],
  components: { LiteHome, LiteCopy, LiteAnalyzer, LiteDescription },
};
</script>
