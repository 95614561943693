<template>
  <v-app-bar color="white" flat>
    <div class="d-flex align-center">
      <h1><router-link to="/" :style="logoStyle">Teamvis.</router-link></h1>
    </div>

    <v-spacer></v-spacer>
    <v-btn text href="#procedure" color="#333">
      {{ $t("howtouse") }}
    </v-btn>
    <v-btn
      text
      href="https://drive.google.com/file/d/18Ik5fkQOkuPsd4CxGvKqDqv6VQVbB9ma/view?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
      color="#333"
    >
      {{ $t("examples") }}
    </v-btn>
    <v-btn
      text
      href="https://drive.google.com/file/d/1WFOHr5DzUVbPLn1WJyWIEI4smgaS_qzp/view?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
      color="#333"
    >
      {{ $t("privacypolicy") }}
    </v-btn>
    <v-btn text href="https://ischool.or.jp/" color="#333">
      {{ $t("aboutus") }}
    </v-btn>
    <v-btn text color="#333" class="mr-5" @click="switch_lang()">JP/EN</v-btn>
    <amplify-sign-out v-if="authState === 'signedin'"></amplify-sign-out>
    <v-btn v-else to="/signin" color="#E03616">
      {{ $t("login") }}
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: ["authState"],
  data: () => {
    return {
      logoStyle: {
        "text-decoration": "none",
        color: "black",
        corsor: "pointer",
      },
    };
  },
  methods: {
    switch_lang: function() {
      if (this.$root.$i18n.locale == "ja") {
        this.$root.$i18n.locale = "en";
      } else {
        this.$root.$i18n.locale = "ja";
      }
    },
  },
};
</script>
