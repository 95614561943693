var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"upload ma-4"},[_c('v-row',[_c('h1',{staticClass:"text-h2 ma-4 mb-8"},[_vm._v(" meetings "),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","color":"indigo"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("New Meeting")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"workshop","required":""},model:{value:(_vm.workshop),callback:function ($$v) {_vm.workshop=$$v},expression:"workshop"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"team","required":""},model:{value:(_vm.team),callback:function ($$v) {_vm.team=$$v},expression:"team"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"numer of members","type":"number","required":""},model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"2"}},[_c('v-select',{attrs:{"items":Array.from(
                          Array(24),
                          function (v, k) { return ('0' + k).slice(-2) + ':'; }
                        ),"label":"hour","required":""},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"2"}},[_c('v-select',{attrs:{"items":Array.from(
                          Array(60),
                          function (v, k) { return ('0' + k).slice(-2) + ':'; }
                        ),"label":"minute","required":""},model:{value:(_vm.minute),callback:function ($$v) {_vm.minute=$$v},expression:"minute"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"2"}},[_c('v-select',{attrs:{"items":Array.from(Array(60), function (v, k) { return ('0' + k).slice(-2); }),"label":"sec","required":""},model:{value:(_vm.second),callback:function ($$v) {_vm.second=$$v},expression:"second"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCreateMtg}},[_vm._v(" Create ")])],1)],1)],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.mtgs,"options":_vm.table_options},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.onClickMtg}}),_c('amplify-authenticator')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }